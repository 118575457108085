import React from 'react';

import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import IntroPage from '../../../components/ContentType/IntroPage/IntroPage';
import TextVisuelTwoCol from '../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol';
import VisuelTextOverlappedBlock from '../../../components/ContentType/VisuelTextOverlappedBlock/VisuelTextOverlappedBlock';
import KeyNumbers from '../../../components/KeyNumbers/KeyNumbers';
import PageBanner from '../../../components/PageBanner/PageBanner';
import ScrollPageNav from '../../../components/ScrollPageNav/ScrollPageNav';
import Seo from '../../../components/Seo/Seo';
import Layout from '../../../Layout';
import { Button } from '../../../Molecules/Button/Button';
import ShapeBackground from '../../../Molecules/ShapeBackground/ShapeBackground';
import { TitlePage } from '../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../Molecules/TitleSection/TitleSection';
import { getImage, getBreadCrumb } from '../../../Utils/Utils';

import './styles.scss';

const classNames = require('classnames');

const AboutUsPage = ({ data, pageContext }) => {
  const { locale } = pageContext;
  const TopBannerData = data.TopBanner?.edges[0]?.node;
  const allBlocs = data.allBlocs?.edges[0]?.node.relationships?.field_blocs;
  const innerNavArr = [];
  const imageArray = data.allImages?.edges;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, TopBannerData.path?.alias);

  allBlocs?.forEach((block, i) => {
    if (i > 0) {
      innerNavArr.push({
        title: block.field_title?.processed,
        section: 'scroll_to' + '_' + i
      });
    }
  });

  const getSectionName = (title) => {
    let secName = '';
    innerNavArr.forEach((item) => {
      if (item.title === title) secName = item.section; return false;
    });
    return secName;
  };

  const linkButtonHandler = (e) => {
    window.open(e.currentTarget.getAttribute('data-url'), '_self');
  };

  return (
    <Layout imagesArray={imageArray}>
      <Seo
        title={TopBannerData.title}
        description="Description de la page About us"
      />
      <div className={classNames('page_template', 'about_us_page', 'zero_bottom_padding')}>
        <ScrollPageNav
          data={innerNavArr}
        />
        {TopBannerData?.relationships?.field_blocs.map((block, index) => (

          <div key={index}>

            {block.relationships ? <PageBanner visuel={getImage(imageArray, block.relationships?.field_image?.drupal_internal__mid)?.childImageSharp?.fluid} scroll={true}>

              <div className="wrapper_page">
                <Breadcrumb
                  page_banner={true}
                  data={{
                    parentPage: { title: breadCrumb?.parent?.name, url: breadCrumb?.parent?.link },
                    currentPage: { title: breadCrumb?.current?.name, url: breadCrumb?.current?.link },
                    locale: TopBannerData.langcode
                  }}
                />

                <TitlePage
                  color="color_white"
                  title={TopBannerData.title}
                  description={block.body?.processed}
                />
              </div>
            </PageBanner> : null}
          </div>

        ))}

        {allBlocs?.map((block, i) => {
          switch (block.__typename) {
            case 'block_content__block_notre_mission':
              return (
                <IntroPage
                  customClass={getSectionName(block.field_title?.processed)}
                  key={i}
                  title={block?.field_title?.processed}
                  subtitle={block?.field_subtitle?.processed?.replace(/<p[^>]*>/g, '')}
                  visuel={getImage(imageArray, block.relationships?.field_image?.drupal_internal__mid)?.childImageSharp}
                  text={block?.body?.processed}
                />
              );

            case 'block_content__block_notre_raison':
              return (
                <section className={classNames('section_content', getSectionName(block.field_title?.processed))} key={i}>
                  <div className="wrapper_page_xs">
                    <TitleSection
                      title={block?.field_title?.processed}
                      description={block?.field_subtitle?.processed?.replace(/<p[^>]*>/g, '')}
                      type="arrow"
                    />
                    <TextVisuelTwoCol
                      orderInverse={true}
                      extremeRight={true}
                      extactHalf={true}
                      alignLeft={true}
                      visuel={getImage(imageArray, block.relationships?.field_image?.drupal_internal__mid)?.childImageSharp}
                      text={block?.body?.processed}
                    />
                  </div>
                </section>
              );

            case 'block_content__block_notre_ambition':
              return (
                <section className={classNames('section_content', 'our_ambition', getSectionName(block.field_title?.processed))} key={i}>
                  <ShapeBackground
                    top="left"
                    bottomInfinite={true}
                    color="catskill_white"
                  >
                    <div className="wrapper_page_xs">
                      <TitleSection
                        title={block?.field_title?.processed}
                        description={block?.field_subtitle?.processed?.replace(/<p[^>]*>/g, '')}
                        type="arrow"
                      />
                      <TextVisuelTwoCol
                        orderInverse={false}
                        extremeLeft={true}
                        visuel={getImage(imageArray, block.relationships?.field_image?.drupal_internal__mid)?.childImageSharp}
                        text={block?.body?.processed}
                      />
                    </div>
                  </ShapeBackground>
                </section>
              );

            case 'block_content__block_nos_valeurs':
              return (
                <section className={classNames('section_content our_values', getSectionName(block.field_title?.processed))} key={i}>
                  <ShapeBackground
                    top="left"
                    bottom="left"
                    color="bleu"
                    background="catskill_white"
                  >
                    <div className="wrapper_page_xs">
                      <TitleSection
                        title={block.field_title?.processed}
                        type="arrow"
                        h2color='white'
                      />
                    </div>
                    <VisuelTextOverlappedBlock
                      visuel={getImage(imageArray, block.relationships?.field_image?.drupal_internal__mid)?.childImageSharp}
                      bgColor='color_bleu_sky'
                      alt=""
                      text={block.body?.processed}
                    />
                    <div className="wrapper_page_xs">

                      {block.relationships.field_values.map((textBlock, j) => (
                        <div className={classNames('details_values_container', block.relationships?.field_values?.length - 1 == j ? 'last' : '')} key={j}>
                          <div className="details_values">
                            <div className="details" dangerouslySetInnerHTML={{ __html: textBlock.field_bloc_text_1?.processed }}>
                            </div>
                            <div className="values" >
                              <div className="txt" dangerouslySetInnerHTML={{ __html: textBlock.field_bloc_text_2?.processed }} />
                              {j == block.relationships?.field_values?.length - 1 &&
                                <div className="cross_wrapper_extreme_right bottom_visuel">
                                  <div className="visuel">
                                    {block?.relationships?.field_image_2?.drupal_internal__mid &&
                                      <Img
                                        imgStyle={{ objectFit: 'contain' }}
                                        fluid={getImage(imageArray, block.relationships?.field_image_2?.drupal_internal__mid)?.childImageSharp?.fluid}
                                        alt=""
                                      />
                                    }
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      ))}

                    </div>
                  </ShapeBackground>
                </section>
              );

            case 'block_content__block_notre_origine':
              return (
                <section className={classNames('section_content', 'our_origin', getSectionName(block.field_title?.processed))} key={i}>
                  <ShapeBackground
                    bottom="right"
                    color="catskill_white"
                  >
                    <div className="wrapper_page_xs">
                      <TitleSection
                        title={block.field_title?.processed}
                        description={block.field_subtitle?.processed?.replace(/<p[^>]*>/g, '')}
                        type="arrow"
                      />
                      <div className="two_col_text_cta">
                        <div dangerouslySetInnerHTML={{ __html: block.field_bloc_text_1[0]?.processed }} />
                        <div >
                          <div dangerouslySetInnerHTML={{ __html: block.field_bloc_text_2?.processed }} />
                          <Button
                            label={block.field_link?.title}
                            primary={true}
                            data-url={block.field_link?.url}
                            onClick={linkButtonHandler}
                          />
                        </div>
                      </div>
                    </div>
                  </ShapeBackground>
                </section>
              );

            case 'block_content__block_chiffres_clefs':
              // eslint-disable-next-line no-case-declarations
              const dataArr = [];
              block.relationships?.field_values?.forEach((item) => {
                dataArr.push({
                  number: item.field_chiffre,
                  title: item.field_legend?.processed
                });
              });
              return (
                <KeyNumbers
                  key={i}
                  title={block.field_title?.processed}
                  subtitle={block.body?.processed?.replace(/<p[^>]*>/g, '')}
                  sectionName={getSectionName(block.field_title?.processed)}
                  textColor="white"
                  backgroundImg={getImage(imageArray, block.relationships?.field_image?.drupal_internal__mid)?.publicURL}
                  datas={dataArr}
                  locale={locale}
                />
              );

            default:
              break;
          }
        })}

      </div>
    </Layout>
  );
};

export const query = graphql`
  query aboutUsTemplateQuery(
    $locale: String!
    $slug: String!, $imageID: [Int!]
  ) {
    TopBanner: allNodePageQuiSommesNous(
      filter: {
        langcode: {eq: $locale},
        path: {alias: {eq: $slug}},
        status: {eq: true}
      }
    ) {
      edges {
        node {
          drupal_internal__nid
          langcode
          path {
            alias
            langcode
          }
          title
          status
          relationships {
            field_blocs {
              ... on Node {
                ... on block_content__banner {
                  id
                  langcode
                  relationships {
                    field_image {
                      drupal_internal__mid
                    }
                  }
                  body {
                    processed
                  }
                }
              }
            }
          }
        }
      }
    }
    allBlocs: allNodePageQuiSommesNous(
      filter: {
        langcode: {eq: $locale},
        path: {alias: {eq: $slug}},
        status: {eq: true}
      }
    ) {
      edges {
        node {
          drupal_internal__nid
          langcode
          path {
            alias
          }
          title
          status
          relationships {
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__banner {
                  id
                  langcode
                  relationships {
                    field_image {
                      drupal_internal__mid
                    }
                  }
                  body {
                    processed
                  }
                }
                ... on block_content__block_notre_mission {
                  body {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  field_title {
                    processed
                  }
                  relationships {
                    field_image {
                      drupal_internal__mid
                    }
                  }
                }
                ... on block_content__block_notre_ambition {
                  id
                  body {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  field_title {
                    processed
                  }
                  relationships {
                    field_image {
                      drupal_internal__mid
                    }
                  }
                }
                ... on block_content__block_notre_raison {
                  id
                  body {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  field_title {
                    processed
                  }
                  relationships {
                    field_image {
                      drupal_internal__mid
                    }
                  }
                }
                ... on block_content__block_nos_valeurs {
                  body {
                    processed
                  }
                  field_title {
                    processed
                  }
                  relationships {
                    field_values {
                      field_bloc_text_1 {
                        processed
                      }
                      field_bloc_text_2 {
                        processed
                      }
                    }
                    field_image {
                      drupal_internal__mid
                    }
                    field_image_2 {
                      drupal_internal__mid
                    }
                  }
                }
                ... on block_content__block_notre_origine {
                  id
                  field_bloc_text_1 {
                    processed
                  }
                  field_bloc_text_2 {
                    processed
                  }
                  field_link {
                    title
                    url
                    uri
                  }
                  field_subtitle {
                    processed
                  }
                  field_title {
                    processed
                  }
                }
                ... on block_content__block_chiffres_clefs {
                  body {
                    processed
                  }
                  field_title {
                    processed
                  }
                  relationships {
                    field_image {
                      drupal_internal__mid
                    }
                    field_values {
                      field_legend {
                        processed
                      }
                      field_chiffre
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allImages: allMediaImage(
      filter: {
        langcode: {eq: "fr"},
        drupal_internal__mid: {in: $imageID}
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              localFile {
                publicURL
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                  fixed(width: 332, height: 332) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          langcode
        }
      }
    }
    allMenu :   allMenuLinkContentMenuLinkContent(
      filter: {langcode: {eq: $locale}}
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
  }
`;

AboutUsPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
};

export default AboutUsPage;
